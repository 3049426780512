/* tslint:disable */
export const environment = {
  production: false,
  reduxToolsEnabled: true,
  reduxMaxAge: 100,
  appName: 'console',
  brandCode: 'sidedrawer',
  keycloakDisabled: true,
  // APIS -----------------------------------------------------------
  configApi: 'https://api-sbx.sidedrawersbx.com/api/v1/configs/',
  developersApi: 'https://api-sbx.sidedrawersbx.com/api/v1/developers/',
  tenantApi: 'https://api-sbx.sidedrawersbx.com/api/v1/tenants/',
  inboxApi: 'https://api-sbx.sidedrawersbx.com/api/v1/inboxes/',
  integrationApi: 'https://api-sbx.sidedrawersbx.com/api/v1/integrations/',
  networksApi: 'https://api-sbx.sidedrawersbx.com/api/v1/networks/',
  recordsApi: 'https://api-sbx.sidedrawersbx.com/api/v1/records/',
  gatewayApi: 'https://api-sbx.sidedrawersbx.com/api/v1/',
  subscriptionApi: 'https://api-sbx.sidedrawersbx.com/api/v1/subscriptions/',
  userApi: 'https://api-sbx.sidedrawersbx.com/api/v1/users/',
  plansApi: 'https://api-sbx.sidedrawersbx.com/api/v1/planrequests/',
  maxElementsPerPage: 50,
  // AUTH0 DEFAULT -----------------------------------------------------------
  audience: 'https://user-api-sbx.sidedrawersbx.com',
  clientID: 'MApc4Um9uVS7tRUU6Aw40gbxvSalGrQY',
  connection: 'Username-Password-Authentication',
  domain: 'auth-sbx.sidedrawersbx.com',
  redirectUri: '/auth/authorize',
  scope: 'openid profile email',
  entityAppRoleName: 'consoleApp',
  // CDN -----------------------------------------------------------
  cdn: 'https://cdn.sidedrawerdev.com/',
  // Versions --------------------------------------------------
  version: '1.10.4',
  dictionaryVersion: 'console_20210501',
  // Token & Session
  inactivityTime: 900,
  inactivityDialogTime: 120,
  refreshTokenTime: 300,
  // Intercom -----------------------------------------------
  intercomAppId: 'eufoidmt',
  intercomHashUrl: 'https://intercom.sidedrawersbx.com/hash',
  // Redirects ----------------------------------------------
  myUrl: 'https://my-sbx.sidedrawersbx.com',
};
